import { ArrowDropDown } from '@mui/icons-material'
import { Button, Menu, MenuItem, Zoom } from '@mui/material'
import { useState, MouseEvent } from 'react'
import { History } from '@/services/history'

export function Company() {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const redirectToService = (serviceName: string) => {
    History.push(`${serviceName}`)
    handleClose()
  }

  return (
    <>
      <Button
        className='hamburger'
        variant="text"
        aria-controls="company-menu"
        aria-haspopup="true"
        onClick={handleClick}
        
        style={{
          fontSize: 17,
          zoom: 1,
          height: '20px',
          paddingTop: '0',
          paddingBottom: '15px'
          
        }}
      >
        <div className="btn__hamb">
        </div>
      </Button>
      

      <Menu
        id="company-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorPosition = {{top: 90, left: 0}}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        PaperProps={{
            style: {
                width: '280px', 
                margin: '0 !important',
                height: 'max-content',
                zoom: 1.19
            },
        }}
      >
        <MenuItem
        className='menuItemMob menuItemVoceToText'
          onClick={() => {
            History.push('/transcriber')
            handleClose()
          }}
        >
          VOICETOTEXT
        </MenuItem>
        
        
        <div className='menuItemBorder'>Услуги</div>
        <div className="menuWrapperMob">
        <MenuItem
        className='menuItemMob'
        onClick={() => {
          redirectToService('/service/development')
          handleClose
        }}
        >
         Разработка
        </MenuItem>
        <MenuItem
        className='menuItemMob'
        onClick={() => {
          redirectToService('/service/integration')
          handleClose
        }}
        >
          Интеграция
          </MenuItem>
          <MenuItem
        className='menuItemMob'
        onClick={() => {
          redirectToService('/service/support')
          handleClose}}
        >
          Поддержка
        </MenuItem>
        </div>
        <MenuItem
        className='menuItemMob'
          onClick={() => {
            History.push('/contacts')
            handleClose()
          }}
        >
          Контакты
        </MenuItem>
      </Menu>
    </>
  )
}
