import styled from '@emotion/styled'
import { ReactNode, Suspense } from 'react'

type Props = {
  children: ReactNode
}

export function SuspenseLoader({ children }: Props) {
  return <Suspense fallback={<SuspenseLoadingIndicator />}>{children}</Suspense>
}

const SuspenseLoadingIndicator = styled.div`
  position: fixed;
  width: 100vw;
  height: 3px;
  left: 0;
  top: 0;
`
