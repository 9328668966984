import { useEffect, useState } from 'react'
import { AppBar, Toolbar, Typography, Container, Button } from '@mui/material'
import { Services } from './Services'
import { Company } from './Company'
import { History } from '@/services/history'
import logo from '@/static/logo/logo.png'
import * as S from './styles'

export function NavBar() {
  const [scrollOpacity, setScrollOpacity] = useState(0)
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = () => {
    const scrollPosition = window.scrollY
    const scrollRange = 500

    const opacity = Math.min(scrollPosition / scrollRange, 1)
    setScrollOpacity(opacity)
  }

  return (
    <AppBar
      position="fixed"
      style={{
        backgroundColor: `rgba(79, 82, 110, ${scrollOpacity})`,
        boxShadow: 'none',}}
    >
      
      <Container
 style={{
    backgroundColor: `rgba(79, 82, 110, ${scrollOpacity})`,
    boxShadow: 'none',
  }}
  className='containerContent'
  maxWidth={false}
  disableGutters = {true}
      >
        <Toolbar disableGutters = {true}>
          <S.NavBarContainer>
            <Typography variant="h6">
              <S.Image src={logo} onClick={() => History.push('/')} alt="Logo" height={40} className='logo' />
            </Typography>
            <S.BtnSection>
              <Button variant="text" onClick={() => History.push('/transcriber')} className='btns'>
                {'VoiceToText'}
              </Button>
              <Services />
              <Button variant="text" onClick={() => History.push('/contacts')} className='btns'>
                {'Контакты'}
              </Button>
              <Company/>
            </S.BtnSection>
          </S.NavBarContainer>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
