import { colors } from '@/ui/colors'
import styled from '@emotion/styled'
import { AppBar } from '@mui/material'

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 0;
  min-height: 300px;
`

export const Footer = styled(AppBar)`
  background-color: ${colors.color3};
`

export const InfoMap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 0 15px 25px;
  border-left: solid 2px grey;
  flex-wrap: wrap;
`

export const RowTitle = styled.div`
  font-size: clamp(1rem, 0.6527rem + 0.7242vw, 3.5rem);
  color: ${colors.color5};
  margin-bottom: 10px;
  @media (min-width: 2000px){
    font-size: 1.275rem;
  }
`

export const RowTitleLink = styled(RowTitle)`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

export const Row = styled.div`
  font-size: clamp(1rem, 0.5527rem + 0.7242vw, 3.5rem);
  @media (min-width: 2000px){
    font-size: 1.075rem;
  }
`

export const RowLink = styled(Row)`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

export const IconRow = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`

export const ContactIcon = styled.img`
  width: 50px;
  height: 50px;

  &:hover {
    cursor: pointer;
  }
`

export const LinkWrapper = styled.a``

export const Image = styled.img`
zoom: 1.3;
  &:hover {
    cursor: pointer;
  }

`