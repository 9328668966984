import { lazy } from 'react'
import { Switch, Route, Redirect, RouteProps } from 'react-router-dom'

import { SuspenseLoader } from './SuspenseLoader'
import { Container } from '@mui/material'

const HomePage = lazy(() => import('@/pages/HomePage/HomePage'))
const TranscriberPage = lazy(() => import('@/pages/TranscriberPage/TranscriberPage'))
const ServicePage = lazy(() => import('@/pages/ServicePage/ServicePage'))
const AboutUsPage = lazy(() => import('@/pages/AboutUsPage/AboutUsPage'))
const ContactsPage = lazy(() => import('@/pages/ContactsPage/ContactsPage'))

function BaseRoute(props: RouteProps) {
  const { path, exact, children } = props

  return (
    <Route path={path} exact={exact}>
      <SuspenseLoader>{children as any}</SuspenseLoader>
    </Route>
  )
}

export function Routes() {
  return (
    <Container style={{ minHeight: 'calc(100vh - 424px)', paddingLeft: 0, paddingRight: 0, margin: 0, maxWidth: 4000 }}>
      <Switch>
        <BaseRoute path="/" exact>
          <Redirect to="/home" />
        </BaseRoute>

        <BaseRoute path="/home">
          <HomePage />
        </BaseRoute>

        <BaseRoute path="/transcriber">
          <TranscriberPage />
        </BaseRoute>

        <BaseRoute path="/service/:serviceId" exact>
          <ServicePage />
        </BaseRoute>

        <BaseRoute path="/about">
          <AboutUsPage />
        </BaseRoute>

        <BaseRoute path="/contacts">
          <ContactsPage />
        </BaseRoute>
      </Switch>
    </Container>
  )
}
