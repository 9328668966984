import { Container, Toolbar, Typography } from '@mui/material'
import { History } from '@/services/history'
import logo from '@/static/logo/logo.png'
import VKIcon from '@/static/icon/vkontakte.png'
import TGIcon from '@/static/icon/telegramDark.png'
import * as S from './styles'

export function Footer() {
  const vkLink = 'https://vk.com/speechai'
  const tgLink = 'https://t.me/sspeechAI'
  const mailbox = 'mailto:info@speechai.ru'

  return (
    <S.Footer position="static">
      <Container
      className='containerContent' 
      disableGutters = {true}
      maxWidth = {false}>
        <Toolbar
        disableGutters = {true}>
          <S.FooterContainer>
            <Typography variant="h6" style={{ marginBottom: '30px' }} onClick={() => History.push('/')}>
              <S.Image src={logo} alt="Logo" height={40} className='logo' />
            </Typography>

            <S.InfoMap>
              <S.Item>
                <S.RowTitleLink onClick={() => History.push('/contacts')}>
                  СВЯЖИТЕСЬ С НАМИ
                </S.RowTitleLink>
                <a href={mailbox} className='linkFooter'><S.Row>info@speechai.ru</S.Row></a>
                <S.IconRow>
                  <S.LinkWrapper href={vkLink} target="_blank" rel="noopener noreferrer">
                    <S.ContactIcon src={VKIcon} />
                  </S.LinkWrapper>

                  <S.LinkWrapper href={tgLink} target="_blank" rel="noopener noreferrer">
                    <S.ContactIcon src={TGIcon} />
                  </S.LinkWrapper>
                </S.IconRow>
              </S.Item>

              <S.Item>
                <S.RowTitle>УСЛУГИ</S.RowTitle>
                <S.RowLink onClick={() => History.push('/service/development')}>
                  Разработка
                </S.RowLink>
                <S.RowLink onClick={() => History.push('/service/integration')}>
                  Интеграция
                </S.RowLink>
                <S.RowLink onClick={() => History.push('/service/support')}>Поддержка</S.RowLink>
              </S.Item>

              <S.Item>
                <S.RowTitleLink onClick={() => History.push('/transcriber')}>
                  VOICEtoTEXT
                </S.RowTitleLink>
              </S.Item>

              <S.Item>
                <S.RowTitleLink>О КОМПАНИИ</S.RowTitleLink>
                <S.Row>ООО "СПИЧ ИИ"</S.Row>
                <S.Row>ИНН: 7735197564</S.Row>
                <S.Row>КПП: 773501001</S.Row>
                <S.Row>ОГРН: 1227700825131</S.Row>
              </S.Item>
            </S.InfoMap>
          </S.FooterContainer>
        </Toolbar>
      </Container>
    </S.Footer>
  )
}
