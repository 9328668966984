import { useState, MouseEvent } from 'react'
import { ArrowDropDown } from '@mui/icons-material'
import { Button, Menu, MenuItem } from '@mui/material'
import { History } from '@/services/history'

export function Services() {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const redirectToService = (serviceName: string) => {
    History.push(`/service/${serviceName}`)
    handleClose()
  }
return (
  <>
    <Button
      variant="text"
      aria-controls="services-menu"
      aria-haspopup="true"
      onClick={handleClick}
      className="srvice-menu"
      style={{
        fontSize: 17,
      }}
    >
      {'Услуги'}
      <ArrowDropDown />
    </Button>
    

    <Menu
      id="company-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
      PaperProps={{
        style: {
          display: 'grid',
          gap: '15px',
          padding: '10px',
        },
      }}
    >
      <MenuItem onClick={() => redirectToService('development')}>Разработка</MenuItem>
        <MenuItem onClick={() => redirectToService('integration')}>Интеграция</MenuItem>
        <MenuItem onClick={() => redirectToService('support')}>Поддержка</MenuItem>
    </Menu>
  </>
)
}
