import styled from '@emotion/styled'
import { colors } from '@/ui/colors'

export const NavBarContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  
`

export const BtnSection = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-left: 10px;
  & > button {
    color: ${colors.textContrast};
  }
  @media (max-width: 980px) {
    & > button {
     color: ${colors.textContrast};
     display: none;
    }
    & > .hamburger { 
      display: block;
    }
  }
  @media (min-width: 981px) {
    & > .hamburger { 
      display: none;
    }
  }
}
`

export const Image = styled.img`
zoom: 1.3;
  &:hover {
    cursor: pointer;
  }

`
export const Container = styled.div`
@media (min-width: 600px) {
  padding-right: 0px;
  padding-left: 0px;
}
`