import { BrowserRouter,Router } from 'react-router-dom';
import { NavBar } from '../NavBar';
import { Routes } from '@/ui/Routes';
import { History } from '@/services/history'
import { Footer } from '../Footer';
import * as S from './styles'

export function App() {
  History.listen((_: any) => {
    window.scrollTo(0, 0) })

  return (
    <S.AppWrapper>
      <NavBar />
      
      <Router  history={History}>
        <Routes />
      </Router>

      <Footer />
    </S.AppWrapper>
  );
}
