export const colors = {
  accent: '#eac41a',
  color1: '#4f526e',
  color2: '#090815',
  color3: '#262840',
  color4: '#e1e1f0', // footerText
  color5: '#8085a6', // footerTitle
  text: '#000',
  textContrast: '#fff',
  backgroundColorContacts: '#696b7b'
}
